<template>
<section class="hero-wrap clearfix" style="background-size: cover; background-repeat: no-repeat; background-image: url(images/flabra3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container container-top">
        <div class="text">
            <h1>Version History</h1>

            <h3>0.87.0 2025-03-10</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-068 (released on March 10th, 2025) has been added to the repository</li>
                <li>Fixed a few inaccuracies in recent MetaInfo items</li>
            </ul>

            <h3>0.86.2 2025-03-08</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-044 (Tons of shit!)</li>
            </ul>

            <h3>0.86.1 2024-10-23</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-045 (Fötöisemöi)</li>
            </ul>

            <h3>0.85.0 2024-10-09</h3>
            <h4>Code</h4>
            <ul>
                <li>We're currently messing around with a few libraries to check how we can improve displaying certain views. For a first test, we've touched both the <strong>relases</strong> and <strong>podcasts</strong> overviews. Anything else should approximately remain the same.</li>
                <li>Finally dropped the initial "We're back" view including its background video, because at this point, we just <strong>are</strong> back. No point to emphasize it anymore.</li>
            </ul>

            <h3>0.84.0 2024-09-04</h3>
            <h4>Code</h4>
            <ul>
                <li>Optimized loading times of Album and Broadcast lists by increasingly relying on the existing cache</li>
                <li>Removed rocket-loader.js library from front end code in order to display list pages early, even while images are still loading</li>
            </ul>

            <h3>0.83.1 2024-08-22</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released GW38 on August 22nd, 2024 (fully referenced)</li>
            </ul>
            
            <h3>0.82.1 2024-08-22</h3>
            <h4>Code</h4>
            <ul>
                <li>Deactivated the month-related phrasing as it caused rendering issues in a few cases.</li>
            </ul>

            <h3>0.82.0 2024-07-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for:
                    <ul>
                        <li>FlaBra-052 (Where is this going)</li>
                        <li>FlaBra-051 (Dark side of Myshoe)</li>
                        <li>FlaBra-050 (Randbezirk Altona)</li>
                        <li>FlaBra-049 (TekknoGym)</li>
                        <li>FlaBra-048 (Löngplaeyear)</li>
                        <li>FlaBra-047 (Unmanaged time)</li>
                        <li>FlaBra-046 (Pop de la Kombüse: dreht frei)</li>
                    </ul>
                </li>
            </ul>

            <h3>0.81.0 2024-06-27</h3>
            <h4>Code</h4>
            <ul>
                <li>Improved MetaInfo output; month-related events will now be phrased appropriately, and if composition and lyrics for a given song happened at the same time, the statement will also be compacted correspondingly.</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-058 (Handmade, imperfect shit)</li>
            </ul>

            <h3>0.80.0 2024-06-24</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for:
                    <ul>
                        <li>FlaBra-061 (Faergötten cläessäics)</li>
                        <li>FlaBra-060 (Workina Machina)</li>
                        <li>FlaBra-053 (PDLK: wart auf mir)</li>
                        <li>FlaBra-054 (Daemonic Pussygrabber)</li>
                    </ul>
                </li>
                <li>Added bonustrack <strong>Atempause</strong> as a takeout of FlaBra-067 (Bassless).</li>
            </ul>

            <h3>0.79.1 2024-06-14</h3>
            <h4>Code</h4>
            <ul>
                <li>Prettified RFGW podcast descriptions. In previous versions, descriptions were displayed in a single block despite the fact that they actually contain paragraphing information. The current version fixes the issue, which definitely improves readability. This change was introduced in order to keep up visually with various podcast clients that consume our recently introduced <strong>rss.xml</strong>.</li>
                <li>Improved the wording in the feature introduced with 0.79.0.</li>
            </ul>

            <h3>0.79.0 2024-06-13</h3>
            <h4>Code</h4>
            <ul>
                <li>There are certain albums (especially older ones) which include songs that are not suitable for every audience. Those songs might or might not be accessible for everybody, depending on the <strong>AccessLevel</strong>, which is also true if you download a whole album in ZIP format. Since this behavior was implemented right from the start, but it was never mentioned anywhere, the new version points out if you are able to access every song, and if the downloaded ZIP file would be incomplete.</li>
            </ul>

            <h3>0.78.0 2024-06-09</h3>
            <h4>Code</h4>
            <ul>
                <li>Added support for <strong>rss.xml</strong> podcast feeds. You will find an option to subscribe (<img src="/images/rss.png" height="16/">) in the podcasts section, once you're authorized.</li>
            </ul>

            <h3>0.77.0 2024-05-31</h3>
            <h4>Code</h4>
            <ul>
                <li>We now include <strong>Censorship information</strong> for broadcasts; if a broadcast is provided in censored AudioFormat, this will be indicated in the detail view. It will also state which version you will be listening to based on your current AccessLevel.</li>
            </ul>

            <h3>0.76.0 2024-05-27</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-067 has been added to the repository and its release is scheduled for tomorrow</li>
            </ul>

            <h3>0.75.0 2024-05-16</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-062.</li>
            </ul>

            <h3>0.74.1 2024-05-11</h3>
            <h4>Code</h4>
            <ul>
                <li>In previous versions, you could confuse the API when two or more distinct songs with the same name by the same interpret were put on different albums; this resulted in a single song mapped to all mentioned albums, and the only audio track available came from the last applied definition. Impact: a couple of releases contained wrong audio material. The issue was fixed with this release.</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-063.</li>
            </ul>

            <h3>0.73.1 2024-04-24</h3>
            <h4>Code</h4>
            <ul>
                <li>In the MetaInfo section, when there were more than one recording engineer involved in a given contrib, only the first one was mentioned. That was a bug in the API which is fixed with this release.</li>
            </ul>

            <h3>0.73.0 2024-04-19</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added MetaInfo for FlaBra-064.</li>
            </ul>

            <h3>0.72.0 2024-03-28</h3>
            <h4>Repository</h4>
            <ul>
                <li>Updated MetaInfo for FlaBra-066 and FlaBra-065. Added MetaInfo for FlaBra-059 and FlaBra-055.</li>
            </ul>

            <h3>0.71.0 2024-03-09</h3>
            <h4>Code</h4>
            <ul>
                <li>We now also support adding, removing and renaming 2FA devices on the fly.</li>
            </ul>

            <h3>0.70.1 2024-03-07</h3>
            <h4>Code</h4>
            <ul>
                <li>In the previous release, when playing an item with MetaData and then switching to an item without any MetaData, the app still displayed MetaData of the previous item. This should be fixed with this release.</li>
            </ul>

            <h3>0.70.0 2024-02-10</h3>
            <h4>Code</h4>
            <ul>
                <li>When a specific album is displayed, tapping on the album thumbnail will display an enlarged version of it. This is a limited preview only.</li>
                <li>Headings and content in the <strong>Currenly Playing</strong> section were properly aligned</li>
                <li>The MetaData section won't show up anymore if there's no information to display</li>
            </ul>

            <h3>0.69.0 2024-02-03</h3>
            <h4>Code</h4>
            <ul>
                <li>Added basic 2FA support. This feature is only available if you have a real account (guess what). In order to enable 2FA, login and tap <strong>Profile</strong> under your account (<img src="/images/account.png" height="20"/>). At the bottom, tap <strong>Enable 2FA</strong> to get started. It should be compatible with the most common authenticator apps, and it supports registration of multiple devices.</li>
            </ul>

            <h3>0.68.0 2024-01-20</h3>
            <h4>Code</h4>
            <ul>
                <li>We changed the forward and rewind buttons' time intervals, because their timings were not only uncommon but also cumbersome (+1 and -1 minute on each click): rewind is now 10 seconds, and forward will only skip the next 30 seconds.</li>
            </ul>

            <h3>0.67.0 2024-01-12</h3>
            <h4>Code</h4>
            <ul>
                <li>Feature preview: we've added a simple playlist so you now can actually see all enqueued songs and seek to random tracks. As of now, it's still only possible to enqueue entire albums. In order to do so, navigate to the corresponding album and tap the <strong>Enqueue</strong> icon to the left. The audioplayer's action icons will update and now include an icon for skipping the current track and another icon for toggling the playlist.</li>
                <li>Removed a lot of the very verbose <i>console.log</i> messages.</li>
            </ul>

            <h3>0.66.0 2024-01-08</h3>
            <h4>Code</h4>
            <ul>
                <li>Resurrected the <strong>MetaStore</strong> functionality which goes way back to 2010 - this feature can be investigated by heading to one of the two latest releases at this time (FlaBra-065 and FlaBra-066), and playing any random song; you will see additional information such as composition and recording data in the extra panel.</li>
            </ul>

            <h3>0.65.0 2023-12-31</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released GW37 on December 31st, 2023 (fully referenced)</li>
            </ul>

            <h3>0.64.0 2023-12-04</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW33 is now fully referenced</li>
            </ul>

            <h3>0.63.0 2023-10-24</h3>
            <h4>Code</h4>
            <ul>
                <li>Added PersonType <strong>Alias</strong> in order to indicate that there are two or more names for actually the same thing. This change was introduced because there's band named <strong>FlashBrain</strong> which is just <strong>FlaBra</strong> nowadays.</li>
            </ul>

            <h3>0.62.0 2023-10-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-066 (released on October 11th, 2023) has been added to the repository</li>
            </ul>

            <h3>0.61.0 2023-09-27</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-065 (released on September 27th, 2023) has been added to the repository</li>
            </ul>

            <h3>0.60.0 2023-09-21</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW2 (released on May 2nd, 2007) is available again, with a lot of references missing. Since most of the included tracks were never intended to be released, the status will remain. Due to compliance reasons, this broadcast is only available to registered users with appropriate permissions.</li>
            </ul>

            <h3>0.59.0 2023-09-01</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released the music video for <strong>Silly con Carne</strong> (FlaBra-057: Arbor fuckingitis, tree of your life).</li>
            </ul>

            <h3>0.58.0 2023-08-31</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-064 (released on August 31st, 2023) has been added to the repository</li>
            </ul>

            <h3>0.57.0 2023-07-21</h3>
            <h4>Repository</h4>
            <ul>
                <li>After being out-of-stock for almost three decades, FlaBra-013 (originally released <strong>on tape only</strong> in November 1995) is now available again. As with other releases of that era, all audio was digitized directly from the master tape in early 2000s which, at that time already, suffered from heavy wearout. Hence, the audio quality is quite poor, but at least better than today (which is all wow and flutter). Furthermore, there's no cover art rework, so the only remainder is a thumbnail of the original tape cover scan. Also note that not all songs are publicly available, because many of them just suck.</li>
            </ul>
            <h4>Code</h4>
            <ul>
                <li>Added SongRelation <strong>Inspired</strong> (counterpart: <strong>InspiredBy</strong>) in order to indicate that a song wasn't re-recorded from a source, but only influenced by its existence. This change was introduced, because <strong>Slush Puppy</strong> is heavily borrowing from <strong>II</strong> (Escape...), while never being a real successor.</li>
            </ul>

            <h3>0.56.0 2023-07-14</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-014 (originally released in November 1995), is now available again, although it depends very much on your AccessLevel how much you can get out of it; without any additonal permissions, you're only granted to listen to nine out of 16 tracks.</li>
            </ul>

            <h3>0.55.0 2023-07-08</h3>
            <h4>Repository</h4>
            <ul>
                <li>Completely rebuilt FlaBra-018 (Spastische Comicwurst), because some tracks had audio glitches (e.g. The Times..) and, apart from a very ancient scan of the tape's front cover, there was no cover artwork. So in alignment with FlaBra-017, new cover artwork was created which is fully based on the original input material.</li>
                <li>Added yet some more of that previously missing hi-res cover artwork for the following FlaBra IDs:
                    019 (Captain Trips Crew 1996),
                    017 (Gnu Gnullulu Gnullu Gnu)
                </li>
            </ul>

            <h3>0.54.0 2023-07-05</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added some more of that previously missing hi-res cover artwork for the following FlaBra IDs:
                    029 (Tunderbold-Propeller),
                    028 (Aquarian),
                    027 (Psychomocca),
                    025 (Old Fashioned Spankings),
                    023 (Captain Friedrichs),
                    021 (Music You never wanted to listen to)
                    - to be continued (we're still not done yet).
                </li>
            </ul>

            <h3>0.53.0 2023-07-03</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-063 (released on July 1st, 2023) added to the repository</li>
                <li>Added the five remaining videos related to that exhaustive Orlan Peters Sextett album production in 2008. As with the previous OPS videos, you need to have an account with corresponding permissions in order to watch them.</li>
                <li>Also added and linked the OPS funding trailer to the Orlan Peters Sextett album (released in May 2008).</li>
                <li>The original "The omniscient complex" video was added and linked to Ugal Mekdugal's first album (°).</li>
                <li>Finally added previously missing hi-res cover artwork for the following FlaBra IDs:
                    050 (Randbezirk Altona), 
                    049 (TekknoGym), 
                    048 (Löngplaeyear),
                    047 (Unmanaged time),
                    046 (Pop de la Kombüse: dreht frei),
                    045 (Fötöisemöi),
                    044 (Tons of shit!),
                    043 (Pop de la Kombüse: Vol. breit),
                    042 (The Waikoona Journey),
                    041 (Pop de la Kombüse: eins),
                    040 (3),
                    039 (See what you expect),
                    038 (non-public),
                    037 (Maestowöi),
                    036 (Ösetöföi),
                    035 (oo),
                    034 (Orlan Peters Sextett),
                    033 (non-public),
                    032 (Mother Fiaker),
                    031 (°)
                    - to be continued (we're not done yet).
                </li>
            </ul>

            <h3>0.52.0 2023-06-25</h3>
            <h4>Code</h4>
            <ul>
                <li>Added password reset functionality for real accounts. This is available when you're not logged in and hit any media item. Once redirected to the identity provider, choose <strong>Let me sign in instead</strong>, and after the redirect hit <strong>Forgot your password?</strong>. Please note that each password reset action comes with an additional human challenge (in order to prove you're not a robot).</li>
            </ul>

            <h3>0.51.0 2023-06-21</h3>
            <h4>Repository</h4>
            <ul>
                <li>Added videos related to the first two PDLK compilations: we have <strong>Randbezirk Altona</strong> and <strong>Jede Woche Samstag</strong> (not streamable for everyone) on PDLK1 and <strong>Medchen</strong> on PDLK2. There are still more videos to come.</li>
            </ul>

            <h3>0.50.0 2023-06-16</h3>
            <h4>Code</h4>
            <ul>
                <li>Improved support for video formats. Initially, only <strong>webm</strong> files were provided. In order to improve support for iOS (Safari), the API now transcodes to <strong>mp4</strong> automatically and adds it as another format option.</li>
            </ul>

            <h3>0.49.0 2023-06-09</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-062 (released on June 9th, 2023) has been added to the repository</li>
            </ul>

            <h3>0.48.0 2023-05-26</h3>
            <h4>Code</h4>
            <ul>
                <li>Podcasts can now also have related videos</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Released an artful video adaption of Gerd Laterne's legendary GLS introduction comprised in GW21</li>
            </ul>

            <h3>0.47.0 2023-05-21</h3>
            <h4>Code</h4>
            <ul>
                <li>Added rudimentary support for profile management: when logged in as a real user (which only a handfull of you could ever do), you can now update your display name and your e-mail address.</li>
            </ul>

            <h3>0.46.0 2023-05-07</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released GW36 on May 7th, 2023 (fully referenced)</li>
            </ul>

            <h3>0.45.0 2023-05-01</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW3 (released on June 3rd, 2007) is available again, with some references still missing</li>
            </ul>

            <h3>0.44.0 2023-04-24</h3>
            <h4>Repository</h4>
            <ul>
                <li>Missing references in GW24, GW26 and GW28 were updated; GW24 and GW28 are now fully referenced (GW26 still missing a few snippets)</li>
            </ul>

            <h3>0.43.0 2023-04-22</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-061 (released on April 22nd, 2023) has been added to the repository</li>
            </ul>

            <h3>0.42.0 2023-04-18</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW4 (released on April 23rd, 2008) is available again, with a few references still missing</li>
            </ul>

            <h3>0.41.0 2023-04-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW5 (released on September 14th, 2008) is available again, with a few references still missing</li>
                <li>GW13 was updated, now it's almost fully referenced (single reference still missing)</li>
            </ul>

            <h3>0.40.0 2023-04-10</h3>
            <h4>Code</h4>
            <ul>
                <li>The API now supports future references to be placed in broadcasts/podcasts; a song which has not been released yet can be declared in a radioshow and be released much later - the API will automatically recognize the song in question as <strong>spoiled</strong>. Might sound trivial, but kept us busy for quite some time.</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>GW7 (released on December 24th, 2008) is available again and fully referenced</li>
                <li>GW6 (released on December 11th, 2008) is available again and almost fully referenced</li>
            </ul>

            <h3>0.39.1 2023-04-05</h3>
            <h4>Code</h4>
            <ul>
                <li>Fix: Also applied improved scrolling/page positioning for broadcasts/podcasts</li>
            </ul>

            <h3>0.39.0 2023-04-05</h3>
            <h4>Code</h4>
            <ul>
                <li>Improved scrolling/page positioning to achieve a more natural browser behavior when following links and using the browser's back button</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>GW8 (released on May 16th, 2009) is available again with a single reference missing</li>
            </ul>

            <h3>0.38.0 2023-04-04</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW10 (released on December 6th, 2009) is available again, with very little references still missing</li>
                <li>GW9 (released on September 12th, 2009) is available again and fully referenced</li>
                <li>Added many DMBDIGL references (Der Mann, bei dem ich gerne lerne): GW12 (including 11), GL13, GL17</li>
            </ul>

            <h3>0.37.0 2023-03-31</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW13 (released on July 7th, 2010) is available again, with a few references still missing</li>
                <li>GW12 (including GW11) (released on December 31st, 2009) is available again, with many references still missing</li>
            </ul>

            <h3>0.36.0 2023-03-25</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW14 (released on September 4th, 2010) is available again, with three references still missing</li>
                <li>NAZWJGL (radioplay) references were updated for GW15 (4-6), GW16 (7), GW17 (8) and GW20 (9)</li>
            </ul>

            <h3>0.35.0 2023-03-23</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW15 (released on October 20th, 2010) is available again, with a lot of references still missing</li>
            </ul>

            <h3>0.34.0 2023-03-22</h3>
            <h4>Code</h4>
            <ul>
                <li>Feature preview: we now support continuous streaming of entire albums so you can listen to them from the first to the last track without any further interaction required. This is an <i>ALPHA</i> preview, so nothing besides a "skip track" button is available, but at least you can enqueue several albums in a row.</li>
            </ul>

            <h3>0.33.0 2023-03-18</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW16 (released on December 5th, 2010) is available again, with a few references still missing</li>
            </ul>

            <h3>0.32.1 2023-03-18</h3>
            <h4>Code</h4>
            <ul>
                <li>Fix: playing a bonus track of one release while another release is displayed, when clicking on the "related album" link in the "currently playing" section, the release related to the bonus track will be shown, but the image was not updated at all. This bug was fixed in this release.</li>
            </ul>

            <h3>0.32.0 2023-03-18</h3>
            <h4>Code</h4>
            <ul>
                <li>When previewing a broadcast item, you can now also see and click referenced songs and contribs.</li>
                <li>The audio player's responsiveness was generally improved (especially for e.g. quickly skipping through tracks)</li>
            </ul>

            <h3>0.31.0 2023-03-10</h3>
            <h4>Code</h4>
            <ul>
                <li>Just a few UX improvements - broadcast and album icons were not clickable, which turned out to be not intuitive at all. This was rectified in this release.</li>
            </ul>

            <h3>0.30.0 2023-03-10</h3>
            <h4>Code</h4>
            <ul>
                <li>Broadcast/podcast items now are downloadable as well.</li>
            </ul>

            <h3>0.29.3 2023-03-08</h3>
            <h4>Code</h4>
            <ul>
                <li>Fix: the initial delay for emitting play-trigger was cut down from 3 seconds to 500 milliseconds - this is purely an optimization issue which might become difficult to handle especially on older machines, but we'll keep it this way for the time being.</li>
            </ul>

            <h3>0.29.2 2023-03-08</h3>
            <h4>Code</h4>
            <ul>
                <li>Fix: loading icon keeps spinning, while song is already playing - this issue only happens when playing any audio for the first time. This is a long-time bug which should be fixed by this commit.</li>
            </ul>

            <h3>0.29.1 2023-03-07</h3>
            <h4>Code</h4>
            <ul>
                <li>Fix: upon album download, redirect the user to IdP in case the token has expired. Previously unhandled, this scenario just confronted the user with a 500.</li>
            </ul>

            <h3>0.29.0 2023-03-04</h3>
            <h4>Code</h4>
            <ul>
                <li>We've put album downloads into beta preview. Some albums are not fully up to date as of now, so keep downloading especially less recent releases.</li>
            </ul>

            <h3>0.28.0 2023-02-16</h3>
            <h4>Code</h4>
            <ul>
                <li>There are some internal changes to the deployment, so this does not actually add any value to the site itself :-)</li>
            </ul>

            <h3>0.27.0 2023-02-16</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW17 (released on December 31st, 2010) is available again, with a few references still missing</li>
            </ul>

            <h3>0.26.0 2023-02-13</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-060 (released on February 13th, 2023) has been added to the repository</li>
            </ul>

            <h3>0.25.0 2023-02-08</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW18 (released on February 10th, 2011) is available again, with a few references still missing</li>
            </ul>

            <h3>0.24.0 2023-02-01</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW19 (released on March 9th, 2011) is available again. Although this piece contains about 15 contributions, only 3 of them are referenced. The rest consists of jam recordings, ancient radio-plays and pre-versions of released tracks</li>
            </ul>

            <h3>0.23.0 2023-01-26</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW20 (released on April 14th, 2011) is available again (one past reference missing)</li>
            </ul>

            <h3>0.22.0 2022-12-27</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released GW35 on December 27th, 2022 (with one missing reference)</li>
            </ul>

            <h3>0.21.0 2022-12-26</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW21 (released on June 13th, 2011) is available again, with a few references still missing</li>
            </ul>

            <h3>0.20.0 2022-12-16</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW22 (released on December 5th, 2011) is available again, with a few references still missing</li>
            </ul>

            <h3>0.19.0 2022-12-06</h3>
            <h4>Repository</h4>
            <ul>
                <li>Released GW34 on December 4th, 2022 (with one missing reference)</li>
            </ul>

            <h3>0.18.0 2022-10-15</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-059 has been added to the repository and its release is scheduled for tomorrow</li>
            </ul>

            <h3>0.17.0 2022-10-14</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW23 is available and fully referenced</li>
            </ul>

            <h3>0.16.0 2022-07-23</h3>
            <h4>Repository</h4>
            <ul>
                <li>We've added ten more videos from the exhaustive Orlan Peters Sextett album production in 2008. As with the previous videos, you need to have an account with corresponding permissions in order to watch these.</li>
            </ul>

            <h3>0.15.0 2022-07-03</h3>
            <h4>Code</h4>
            <ul>
                <li>We're still collecting functions which were available in previous releases. This one's heading towards podcasts' visibility, trying to recover most of the functionality of <i>radio.flabra.de</i> - at least, we now have a function to display and play/describe all currently available podcasts!</li>
            </ul>

            <h3>0.14.1 2022-06-24</h3>
            <h4>Code</h4>
            <ul>
                <li>We now have minimal support for broadcast descriptions; once a broadcast is playing, you can click on the player's <i>info</i> icon, which will show the broadcast's description along with the playlist</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-058 has been added to the repository and it was released on June 20th, 2022</li>
            </ul>

            <h3>0.13.0 2022-06-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW33 has been released on June 10th, but the content is not fully referenced yet</li>
            </ul>

            <h3>0.12.0 2022-05-24</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW25 is available and fully referenced</li>
                <li>GW24 is available, but not fully referenced yet</li>
            </ul>

            <h3>0.11.0 2022-05-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW28 is available, but not fully referenced yet</li>
                <li>GW27 is available and fully referenced</li>
                <li>GW26 is available, but not fully referenced yet</li>
            </ul>

            <h3>0.10.0 2021-12-11</h3>
            <h4>Repository</h4>
            <ul>
                <li>FlaBra-057 has been added to the repository and its release is scheduled for tomorrow</li>
            </ul>

            <h3>0.9.2 2021-11-12</h3>
            <h4>Code</h4>
            <ul>
                <li>Fixed missing login redirect when playing video without being signed in</li>
            </ul>

            <h3>0.9.1 2021-11-09</h3>
            <h4>Code</h4>
            <ul>
                <li>Now scaling video fluid-style, according to current viewport</li>
                <li>When a video starts, while the AudioPlayer is active, the audio stream will be paused automatically</li>
                <li>VideoPlayer now displays rudimentary metadata</li>
            </ul>

            <h3>0.9.0 2021-11-08</h3>
            <h4>Code</h4>
            <ul>
                <li>We changed the media streaming strategy, which should improve the overall playback experience; media now start playing with very little buffering</li>
                <li>Introducing video support (<i>ALPHA</i>). Album releases can have related video material. Alas, all videos currently online will only play if you're logged in with a privileged account.</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Added some first video files related to FlaBra-034</li>
            </ul>

            <h3>0.8.3 2021-09-23</h3>
            <h4>Code</h4>
            <ul>
                <li>We now distinguish songs by not only their names, but also the albums they are placed on</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Releases and relations are now also publicly available</li>
            </ul>

            <h3>0.8.2 2021-08-03</h3>
            <h4>Repository</h4>
            <ul>
                <li>Releases FlaBra-020 to FlaBra-017 have been added to the repository</li>
                <li>Including quite a few non-album tracks for FlaBra-021 to FlaBra-017</li>
            </ul>

            <h3>0.8.1 2021-06-25</h3>
            <h4>Code</h4>
            <ul>
                <li>The app will now actively inform you when you hit a song that cannot be played due to your lacking the permissions required by the song's audio data.</li>
            </ul>

            <h3>0.7.1 2021-06-25</h3>
            <h4>Code</h4>
            <ul>
                <li>Just a minor bug fix release :-)</li>
            </ul>

            <h3>0.7.0 2021-06-19</h3>
            <h4>Code</h4>
            <ul>
                <li>Established connection for album-related songs and vice-versa, links to albums are currently buggy and hence disabled.</li>
            </ul>

            <h3>0.6.2 2021-06-14</h3>
            <h4>Code</h4>
            <ul>
                <li>Removed dummy implementation for human challenge endpoint. You now have to actually react to a challenge, and can not simply say "yes", when applying anonymous authorization.</li>
            </ul>

            <h3>0.6.1 2021-06-12</h3>
            <h4>Code</h4>
            <ul>
                <li>Optimized the selection of broadcast sections. Clicking anywhere on an item within the given list will now seek to the specified position in the audio stream. Previous behavior only permitted this when the time marker itself was clicked.</li>
            </ul>

            <h3>0.6.0 2021-06-11</h3>
            <h4>Code</h4>
            <ul>
                <li>You can now list the content of a broadcast and seek to specific sections ("references", i.e. songs and radio-plays). When a broadcast is playing, open the content list by clicking on the <i>info</i> icon (<img src="/images/KnobInfo.png" height="16"/>). The time marks are clickable, and will seek the audio stream to the corresponding position.</li>
            </ul>

            <h3>0.5.3 2021-06-06</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW29 is available and fully referenced</li>
            </ul>

            <h3>0.5.2 2021-06-05</h3>
            <h4>Repository</h4>
            <ul>
                <li>GW30 is available, but still lacks a few references</li>
            </ul>

            <h3>0.5.1 2021-05-30</h3>
            <h4>Repository</h4>
            <ul>
                <li>Releases FlaBra-025 to FlaBra-021 have been added to the repository</li>
                <li>Releases FlaBra-055 to FlaBra-051 now include the original cover artwork as well</li>
            </ul>

            <h3>0.5.0 2021-05-27</h3>
            <h4>Code</h4>
            <ul>
                <li>EmbeddedAudioPlayer now supports fast-forward and fast-rewind (by 60 seconds each)</li>
                <li>EmbeddedAudioPlayer uses a new icon pack (credits: <a href="http://itweek.deviantart.com/">iTweek</a>)</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Releases FlaBra-027 to FlaBra-30 have been added to the repository</li>
                <li>GW32 is available and fully referenced</li>
                <li>GW31 is available and fully referenced</li>
                <li>FlaBra-056 has been added to repository and its release is scheduled for tomorrow</li>
            </ul>

            <h3>0.4.2 2021-05-08</h3>
            <h4>Code</h4>
            <ul>
                <li>First publication, Planet FlaBra is back after almost three years</li>
            </ul>
            <h4>Repository</h4>
            <ul>
                <li>Albums FlaBra-031 up to FlaBra-055 are fully comprised</li>
                <li>GW32 is available, but still lacks a few references</li>
            </ul>
        </div>
    </div>
</section>

</template>

<script>
export default {
  name: 'Changelog',
}
</script>

<style>
</style>
